import React from 'react'
import carter from '../images/carter005.jpg'

const About = ({ article, articleTimeout, closeElem, onClickLink }) => (
  <article
    id="intro"
    className={`${article === 'about' ? 'active' : ''} ${
      articleTimeout ? 'timeout' : ''
    }`}
    style={{ display: 'none' }}
  >
    <h2 className="major">About</h2>
    <span className="image main">
      <img src={carter} alt="All Memories Video with President Jimmy Carter" />
      <figcaption>Filming President Jimmy Carter at the inauguration of Habitats for Humanity in Queens, NY</figcaption>
    </span>

    <p>
      For over 30 years, <strong>All Memories Video</strong> has helped families in the Tri-State area 
      capture and preserve their most cherished memories. With our professional staff and equipment, 
      we're able to provide the utmost quality and service at an affordable price.
    </p>

    <div className="lists">
      <div className="list">
        <h3>Services</h3>
        <p>We offer an array of services that can be tailored to your specific needs:</p>
        <ul>
          <li>Videographer for hire</li>
          <li>Edited Video</li>
          <li>Raw Footage</li>
          <li>Highlight Reel</li>
          <li>Slideshows & Montages</li>
          <li>Video Editing</li>
          <li>Video Transfers (8mm, super8, VHS, etc.)</li>
          <li>Digital & Hardcopies</li>
        </ul>
      </div>
      <div className="list">
        <h3>Types of Events</h3>
        <p>We are available for, but are not limited to following:</p>
        <ul>
          <li>Birthday Parties</li>
          <li>Weddings & Anniversaries</li>
          <li>Religious Ceremonies</li>
          <li>Recitals & Performances</li>
          <li>Community Events</li>
          <li>Other (by request)</li>
        </ul>
      </div>
    </div>

    <p>For more information, contact us at <a href="tel:718-849-3843">(718) 849-3843</a> or leave us a message via our <a href="#" onClick={() => onClickLink('contact')}>Contact form</a>.</p>

    {closeElem()}
  </article>
)

export default About