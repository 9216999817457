import PropTypes from 'prop-types'
import React from 'react'

import About from './About'
import Packages from './Packages'
import Gallery from './Gallery'
import Reviews from './Reviews'
import Contact from './Contact'

class Main extends React.Component {
  componentDidMount() {
    document.addEventListener('keydown', (e) => {
      const { article, onCloseArticle } = this.props
      const articleOpen = ['about', 'packages', 'gallery', 'reviews', 'contact'].includes(article)

      if(articleOpen && e.key === 'Escape') (
        onCloseArticle()
      )
    })
  }

  closeElem = () => (
    <div
      className="close"
      onClick={this.props.onCloseArticle}
    />
  )

  render() {
    const { article, articleTimeout, onClickLink } = this.props;
    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <About article={article} articleTimeout={articleTimeout} closeElem={this.closeElem} onClickLink={onClickLink} />

        {/* <Packages article={article} articleTimeout={articleTimeout} closeElem={this.closeElem} /> */}

        {/* <Gallery article={article} articleTimeout={articleTimeout} closeElem={this.closeElem} /> */}

        {/* <Reviews article={article} articleTimeout={articleTimeout} closeElem={this.closeElem} /> */}

        <Contact article={this.props.article} articleTimeout={this.props.articleTimeout} closeElem={this.closeElem} onCloseArticle={this.props.onCloseArticle} />
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
