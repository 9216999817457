import PropTypes from 'prop-types'
import React from 'react'
import filmCameraAMV from '../images/film-camera-amv.png'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      {/* film camera icon created by varona.io */}
      <img className="icon" src={filmCameraAMV} alt="video camera icon" />
    </div>
    <div className="content">
      <div className="inner">
        <h1>All Memories Video Productions</h1>
        <p>Videography & Editing services</p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button onClick={() => props.onOpenArticle('about')}>About</button>
        </li>
        {/* <li>
          <button onClick={() => props.onOpenArticle('packages')}>Packages</button>
        </li> */}
        {/* <li>
          <button onClick={() => props.onOpenArticle('gallery')}>Gallery</button>
        </li> */}
        {/* <li>
          <button onClick={() => props.onOpenArticle('reviews')}>Reviews</button>
        </li> */}
        <li>
          <button onClick={() => props.onOpenArticle('contact')}>Contact</button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
