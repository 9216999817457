import React from 'react'

const sendEmail = (e) => {
  const { firstName, lastName, phone, email, message } = e.currentTarget;
  e.preventDefault()
  fetch('/.netlify/functions/email', {
    method: "POST",
    body: JSON.stringify({
      name: `${firstName.value} ${lastName.value}`,
      phone: phone.value,
      email: email.value,
      message: message.value
    })
  })
}

const Contact = ({ article, articleTimeout, closeElem, onCloseArticle }) => {
  const initData = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    message: ''
  }

  const [formData, setFormData] = React.useState(initData)

  const { firstName, lastName, phone, email, message } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.currentTarget.getAttribute('name')]: e.currentTarget.value })

  return (
    <article
      id="contact"
      className={`${article === 'contact' ? 'active' : ''} ${articleTimeout ? 'timeout' : ''}`}
      style={{ display: 'none' }}
    >
      <h2 className="major">Contact</h2>
      <form 
        name="contact"
        method="POST"
        onSubmit={(e) => {
          sendEmail(e)
          setFormData(initData)
          onCloseArticle()
        }}
      >
        <div className="field half first">
          <label htmlFor="firstName">First Name*</label>
          <input type="text" name="firstName" id="firstName" value={firstName} onChange={onChange} required />
        </div>
        <div className="field half">
          <label htmlFor="lastName">Last Name*</label>
          <input type="text" name="lastName" id="lastName" value={lastName} onChange={onChange} required />
        </div>

        <div className="field half first">
          <label htmlFor="phone">Phone</label>
          <input type="text" name="phone" id="phone" value={phone} onChange={onChange} />
        </div>
        <div className="field half">
          <label htmlFor="email">Email*</label>
          <input type="email" name="email" id="email" value={email} onChange={onChange} required />
        </div>

        <div className="field">
          <label htmlFor="message">Message*</label>
          <textarea name="message" id="message" rows="4" value={message} onChange={onChange} required></textarea>
        </div>

        <ul className="actions">
          <li>
            <input type="submit" value="Send Message" className="special" />
          </li>
          <li>
            <input type="reset" value="Reset" />
          </li>
        </ul>
      </form>
      
      {closeElem()}
    </article>
  )
}

export default Contact